(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-countdown.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-countdown.js');
"use strict";


const {
  useEffect,
  useRef,
  useCallback,
  useState
} = React;
const useCountdown = _ref => {
  let {
    targetDateTime,
    beginDateTime,
    intervalInSecs = 1
  } = _ref;
  const wasCountingDown = useRef(false);
  const timerRef = useRef(null);
  const [countdown, setCountdown] = useState(null);
  const prevCountdownRef = useRef();
  const targetDateTimeValue = new Date(targetDateTime).getTime();
  const beginDateTimeValue = beginDateTime ? new Date(beginDateTime).getTime() : dateFns.sub(new Date(targetDateTimeValue), {
    minutes: 59,
    seconds: 59
  }).getTime();
  const calculateCountdown = useCallback(() => {
    const currentTimeValue = new Date().getTime();
    if ((!beginDateTimeValue || currentTimeValue >= new Date(beginDateTimeValue).getTime()) && currentTimeValue <= targetDateTimeValue) {
      wasCountingDown.current = true;
      const distance = targetDateTimeValue - currentTimeValue;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      const seconds = Math.floor(distance % (1000 * 60) / 1000);
      let newCountdown = null;
      if (days) {
        newCountdown = "".concat(days, " dag").concat(days > 1 ? 'ar' : '');
      } else if (hours) {
        newCountdown = "".concat(hours + 1, " timmar");
      } else if (minutes) {
        newCountdown = "".concat(minutes + 1, " minuter");
      } else if (seconds) {
        newCountdown = "".concat(seconds, " sekund").concat(seconds > 1 ? 'er' : '');
      }

      if (prevCountdownRef.current !== newCountdown) {
        setCountdown(newCountdown);
      }
      prevCountdownRef.current = newCountdown;
    } else {
      setCountdown(null);
      clearInterval(timerRef.current); 
    }
  }, [beginDateTimeValue, targetDateTimeValue]);
  useEffect(() => {
    setCountdown(null);
    const initializeCountdown = () => {
      calculateCountdown();
      timerRef.current = setInterval(calculateCountdown, intervalInSecs * 1000);
    };
    const now = new Date().getTime();
    if (now < beginDateTimeValue) {
      const delay = beginDateTimeValue - now;
      timerRef.current = setTimeout(initializeCountdown, delay);
    } else {
      initializeCountdown();
    }
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(timerRef.current);
    };
  }, [beginDateTimeValue, calculateCountdown]);
  return countdown;
};
setGlobal('svs.components.poolBetting.hooks.useCountdown', useCountdown);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-draw-query-params.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-draw-query-params.js');
"use strict";


const {
  useLocation
} = ReactRouterDOM;
const {
  useEffect,
  useMemo,
  useState
} = React;
const {
  PARAM_DRAWNUMBER,
  PARAM_PRODUCT_ID
} = svs.components.sport.tipsenShared;
const useDrawQueryParams = () => {
  const location = useLocation();
  const [productId, setProductId] = useState(null);
  const [drawNumber, setDrawNumber] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get(PARAM_DRAWNUMBER)) {
      setDrawNumber(searchParams.get(PARAM_DRAWNUMBER));
    }
    if (searchParams.get(PARAM_PRODUCT_ID)) {
      setProductId(searchParams.get(PARAM_PRODUCT_ID));
    }
  }, [location.search]);
  return useMemo(() => ({
    drawNumber,
    productId
  }), [drawNumber, productId]);
};
setGlobal('svs.components.poolBetting.hooks.useDrawQueryParams', useDrawQueryParams);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-print-tracking.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-print-tracking.js');
"use strict";


const {
  useEffect
} = React;
const {
  useSelector
} = ReactRedux;
const {
  trackEvent
} = svs.components.analytics;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const usePrintTracking = () => {
  const displayName = useSelector(state => selectDisplayName(state));
  useEffect(() => {
    const trackAfterPrint = () => {
      trackEvent({
        category: 'nya_tipsen',
        action: 'print',
        opt_label: displayName
      });
    };
    window.addEventListener('afterprint', trackAfterPrint);
    return () => {
      window.removeEventListener('afterprint', trackAfterPrint);
    };
  }, [displayName]);
};
setGlobal('svs.components.poolBetting.hooks.usePrintTracking', usePrintTracking);

 })(window);